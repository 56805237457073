.ClapButton {
  transition: transform 0.1s;
  border: none;
  outline: none;
  background: transparent;
  font-size: 100px;
  padding: 100px;
  cursor: pointer;
  animation: pulse-on-load 1s ease-in;
  -webkit-tap-highlight-color: transparent;
}


@keyframes pulse-on-load {
  0% { transform: scale(10.0); }
  60% { transform: scale(0.9); }
  80% { transform: scale(1.1); }
  100% { transform: scale(1.0); }
}
