.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 1s forwards;
  overflow: hidden;
}

.App .title {
  color: #444;
  margin: 0;
  height: 0;
}

.App > *:not(.ClapButton) {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}