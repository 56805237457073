.ConnectionStatus {
  position: fixed;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  font-size: var(--label-size);
  color: var(--label-text);
}

.ConnectionStatus .state-dot {
  border-radius: 50%;
  height: 0.8em;
  width: 0.8em;
  margin-right: 0.3em;
  transition: background-color 1s;
  background-color: orange;
}

.ConnectionStatus .state-dot.connected {
  background-color: green;
}
