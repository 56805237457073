.Alert {
  animation: slideIn 0.5s ease forwards;
  transform-origin: top;
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: grid;
  grid-template-areas:
    'icon title action'
    'icon content action';

  grid-template-columns: auto 1fr auto;

  background: #d2deea;
  color: #41668b;
}

@keyframes slideIn {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.Alert .icon {
  grid-area: icon;
  font-size: 20px;
  margin: 0 15px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #97bbda;
}

.Alert .title {
  grid-area: title;
  margin-top: 10px;
  font-size: 0.8em;
  margin-bottom: 5px;
}

.Alert .action {
  grid-area: action;
  width: 80px;
  display: flex;
}

.Alert .action button {
  flex: 1;
  border: none;
  outline: none;
  background: #dce6ef;
  color: #41668b;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.Alert .content {
  grid-area: content;
  margin-bottom: 10px;
}
