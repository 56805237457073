.ShareButton {
  transition-duration: 0.5s;
  transition-property: background-color, box-shadow, color, border-color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: transparent;
  color: #404040;
  padding: 10px 20px;
  font-size: 1.2em;
}

.ShareButton:hover, .ShareButton:active {
  background-color: #fff;
  border-color: transparent;
  color: #202020;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.ShareButton:disabled {
  color: #d0d0d0;
  pointer-events: none;
}